import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import {
  Section,
  Text,
  StyledInput,
  StyledSelect,
  StyledSubmit,
} from './styles'

const url =
  'https://newamericanstoryproject.us14.list-manage.com/subscribe/post?u=bf6f20f22ad7bb70eec092cd6&amp;id=c9c440c52a'

const CustomForm = ({ status, message, onValidated }) => {
  let email
  let name
  let interest
  const submit = () =>
    email &&
    name &&
    interest &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
      INTEREST: interest.value,
    })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50rem',
        margin: '0 auto',
      }}
    >
      {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
      {status === 'error' && (
        <div
          style={{ color: 'red' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          style={{ color: 'green' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <label htmlFor="mce-NAME">Your Name</label>
      <StyledInput id="mce-NAME" ref={node => (name = node)} type="text" />
      <label htmlFor="mce-EMAIL">Email Address</label>
      <StyledInput id="mce-EMAIL" ref={node => (email = node)} type="email" />
      <label htmlFor="mce-INTEREST">I'm Interested In:</label>
      <StyledSelect
        name="INTEREST"
        id="mce-INTEREST"
        ref={node => (interest = node)}
        style={{ fontSize: '2rem', padding: 5, margin: '0.5rem 0' }}
      >
        <option value="">Select...</option>
        <option value="Updates">Updates</option>
        <option value="Volunteering">Volunteering</option>
        <option value="Events">Events</option>
        <option value="Donation">Donation</option>
      </StyledSelect>
      <StyledSubmit onClick={submit}>Submit</StyledSubmit>
    </div>
  )
}

const Newsletter = () => (
  <div>
    <Section bg="black" color="white">
      <Text large>Sign up to get involved with NASP</Text>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </Section>
  </div>
)

export default Newsletter

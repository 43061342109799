import styled from 'styled-components'
import { color, space, maxWidth } from 'styled-system'
import {
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion'
import Link from 'gatsby-link'

export const Container = styled.div`
  flex-shrink: 0;
  .accordion__title {
    // background: none;
    // color: initial;
    // padding: 0;
  }
`

export const StyledAccordionItem = styled(AccordionItem)`
  border: 1px solid;
  margin-top: -1px;
  margin-left: -2rem;
  @media only screen and (max-width: 62rem) {
    margin-right: -2rem;
  }
`

export const StyledAccordionItemTitle = styled(AccordionItemTitle)`
  padding: 2rem;
  margin: 0;
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.lightGray};
  }
  h1 {
    font-family: ${props => props.theme.fonts.serif};
    font-weight: bold;
    display: inline;
    margin: 0;
    background-image: linear-gradient(
      ${props =>
        props.theme.colors.black &&
        `${props.theme.colors.black}, ${props.theme.colors.black}`}
    );
    background-position: 0 1.05em;
    background-repeat: repeat-x;
    background-size: 1px 1px;
    text-decoration: none;
  }

  .accordion__arrow {
    transition: 0.25s;
    right: 3rem;
    height: 1.5rem;
    width: 1.5rem;
    &::before {
      width: 1rem;
      height: 1px;
      background-color: ${props => props.theme.colors.black};
      transform: rotate(90deg);
    }
    &::after {
      width: 1rem;
      height: 1px;
      background-color: ${props => props.theme.colors.black};
      transform: scale(1);
    }
  }
  &[aria-expanded='true'] .accordion__arrow::before,
  &[aria-expanded='true'] .accordion__arrow::after {
    transform: scale(1);
    left: 0;
    right: 0;
    width: 100%;
  }
  &[aria-expanded='false'] .accordion__arrow {
    transform: rotate(90deg);
  }
  &[aria-expanded='true'] .accordion__arrow {
    transform: rotate(0deg);
  }
`

export const StyledAccordionItemBody = styled(AccordionItemBody)`
  position: relative;
  margin: 0;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: 1rem 0 4rem 0;
`

export const Body = styled.div`
  ${space};
  font-size: 1.2rem;
  line-height: 1.3;
  h2 {
    font-family: ${props => props.theme.fonts.serif};
    font-weight: 300;
  }
  p {
    font-family: ${props => props.theme.fonts.sansSerif} !important;
    font-size: 1.4rem !important;
    font-weight: 300;
    margin-bottom: 2rem !important;
  }
  aside {
    margin-top: 4rem;
    position: relative;
    font-weight: 300;
    font-family: ${props => props.theme.fonts.monospace};
    margin-left: 4rem;
    margin-right: 4rem;
    &::before {
      font-weight: 200;
      position: absolute;
      font-size: 2rem;
      margin-right: 1rem;
      content: '\u201C';
      left: -2rem;
      top: -0.1rem;
    }
  }
`

export const Section = styled.div`
  ${color};
  padding: 6rem;
  border-bottom: 1px solid;
  ${space};
  @media only screen and (max-width: 48rem) {
    padding: 6rem 3rem;
  }
`

export const Wrapper = styled.div`
  ${maxWidth};
  margin: 0 auto;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 4.5rem;
  text-align: ${props => (props.large ? 'center' : 'left')};
  max-width: ${props => (props.large ? '18em' : '20em')};
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 62rem) {
    font-size: 3rem;
  }
`

export const Chunk = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
  padding-bottom: 6rem;
  ${props => props.border && `border-bottom: 1px solid`};
  p {
    font-size: 2rem;
    font-family: ${props => props.theme.fonts.serif};
    margin-top: 0;
    margin-bottom: 4rem;
    width: 100%;
  }
  h3 {
    width: 25%;
    margin-top: 0.25rem;
    margin-right: 8rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
  }
  h4 {
    margin-top: -2rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    margin: 0;
    margin-bottom: 4rem;
    padding: 0;
    columns: 2;
    margin-top: 0.25rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
    max-width: 36rem;
    &.single {
      columns: 1;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
  @media only screen and (max-width: 62rem) {
    flex-direction: column;
    h3 {
      margin-bottom: 3rem;
      font-size: 1.5rem;
      width: 100%;
      margin-right: 0;
    }
    p {
      font-size: 1.5rem;
    }
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  font-weight: bold;
  background-image: linear-gradient(
    ${props =>
      props.theme.colors.black &&
      `${props.theme.colors.black}, ${props.theme.colors.black}`}
  );
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  text-decoration: none;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`
export const StyledA = styled.a`
  color: inherit;
  font-weight: ${props => (props.medium ? '500' : 'bold')};
  background-image: linear-gradient(
    ${props =>
      props.underline === 'white'
        ? `${props.theme.colors.white}, ${props.theme.colors.white}`
        : `${props.theme.colors.black}, ${props.theme.colors.black}`}
  );
  background-position: ${props => (props.medium ? '0 1.15em' : '0 1.05em')};
  background-repeat: repeat-x;
  background-size: 1px 1px;
  text-decoration: none;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`

export const StyledInput = styled.input`
  font-weight: 300;
  font-size: 2rem;
  margin: 0.5rem 0;
  margin-bottom: 3rem;
  background: none;
  border: none;
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`

export const StyledSelect = styled.select`
  font-weight: 300;
  font-size: 2rem;
  margin: 0.5rem 0;
  margin-bottom: 3rem;
  background: none;
  border: none;
  border-radius: 0;
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  appearance: none;
  outline: none;
`

export const StyledSubmit = styled.button`
  font-weight: 300;
  font-size: 2rem;
  margin: 0.5rem 0;
  margin-top: 3rem;
  border: none;
  border-radius: 0;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  align-self: center;
  padding: 0.5rem 2rem;
`

export const ResourcesContent = styled.div`
  margin-left: -4rem;
  margin-right: -4rem;
  display: flex;
  color: ${props => props.theme.colors.white};
  div {
    display: flex;
    align-items: center;
    background: ${props => props.theme.colors.mediumGray};
    padding: 4rem 6rem;
    margin: 2rem 0;
    width: 50%;
    &:first-child {
      border-right: 1px solid;
    }
  }
  @media only screen and (max-width: 48rem) {
    flex-direction: column;
    margin: 0;
    div {
      padding: 4rem 2rem;
      margin: 0 1rem;
      width: 100%;
      &:first-child {
        border: none;
        border-bottom: 1px solid;
      }
    }
  }
`

export const Resources = styled.div`
  margin-bottom: 8rem;

  ${props => props.border && `border-bottom: 1px solid`};
  p {
    font-size: 2rem;
    font-family: ${props => props.theme.fonts.serif};
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }
  h3 {
    margin-top: 0.25rem;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
    text-align: center;
  }
  @media only screen and (max-width: 62rem) {
    p {
      font-size: 1.5rem;
    }
  }
`

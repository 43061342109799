import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import ChangeTitle from 'containers/change-title'
import Head from '../components/Pages/Action/Head'
import Content from '../components/Pages/Action/Content'
import Newsletter from '../components/Pages/Action/Newsletter'

import config from '../../data/SiteConfig'

const ActionPage = ({ data }) => {
  // changeTitle('Take Action')

  return (
    <div className="take-action-container">
      <Helmet title={`Take Action | ${config.siteTitle}`} />
      <ChangeTitle title="Take Action" />
      <Head />
      <Content poster={data.posterImage} />
      <Newsletter />
    </div>
  )
}

export default ActionPage

/* eslint no-undef: "off" */
export const query = graphql`
  query ActionPageQuery {
    posterImage: file(relativePath: { regex: "/micah-posters/" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

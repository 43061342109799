import React from 'react'
import { Accordion } from 'react-accessible-accordion'
import Img from 'gatsby-image'

import 'react-accessible-accordion/dist/fancy-example.css'
import {
  Body,
  StyledAccordionItem,
  StyledAccordionItemTitle,
  StyledAccordionItemBody,
  Section,
  Chunk,
  Wrapper,
  StyledA,
  Resources,
  ResourcesContent,
} from './styles'

const Content = ({ poster }) => (
  <div>
    <Section bg="white" color="black">
      <Wrapper maxWidth="80rem">
        <Chunk border>
          <h3>Financial Support</h3>
          <div>
            <p>
              <StyledA
                href="https://donatenow.networkforgood.org/NASP"
                target="_blank"
                rel="noopener noreferrer"
              >
                Donate to NASP
              </StyledA>, With your support, we can continue interviewing
              children and bringing you up-to-date stories and information about
              the new Americans who make up the Welcome Children project. Your
              donations will go toward transcription, editing, video production,
              photography and web design.
            </p>
            <p>
              <StyledA
                href="https://donatenow.networkforgood.org/centrolegaldelaraza"
                target="_blank"
                rel="noopener noreferrer"
              >
                Donate to Centro Legal de la Raza
              </StyledA>, our fiscal sponsor. They provide comprehensive legal
              services to protect and advance the rights of immigrant and
              low-income communities. They have represented many of the children
              whose stories are featured at NASP.
            </p>
          </div>
        </Chunk>
        <Chunk border>
          <h3>Engage Your Representatives</h3>
          <div>
            <p>
              Call, write or email your representatives in the U.S. Government
              to express your concern for refugee children fleeing violence in
              Central America.
            </p>
            <Accordion style={{ border: 'none' }} accordion={false}>
              <StyledAccordionItem>
                <StyledAccordionItemTitle
                  px={['1.5rem', '3rem', '3rem']}
                  pt="2rem"
                  pb="1rem"
                  className="u-position-relative"
                >
                  <h1
                    style={{
                      maxWidth: '36em',
                    }}
                  >
                    Call Congress
                  </h1>
                  <div className="accordion__arrow" role="presentation" />
                </StyledAccordionItemTitle>
                <StyledAccordionItemBody>
                  <Body
                    style={{
                      maxWidth: '36em',
                      fontSize: '1.25rem',
                      lineHeight: '1.4',
                    }}
                    mx={['2rem']}
                  >
                    <h2>Do Not Incarcerate Refugees</h2>
                    <p>
                      Call your congressperson and tell them individuals
                      claiming asylum should be processed as refugees and should
                      not be incarcerated.
                    </p>
                    <p>
                      Look up your representatives{' '}
                      <StyledA
                        medium
                        href="https://www.govtrack.us/congress/members"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </StyledA>.<br />
                      Call the congressional switchboard at 202-224-3121
                    </p>
                    <aside>
                      Guatemala, El Salvador, and Honduras — the countries that
                      make up the Northern Triangle of Central America — are
                      among the most dangerous nations on the planet. The
                      majority of Central Americans coming from the Northern
                      Triangle are fleeing violence. They are refugees and
                      should be treated humanely. They should not be detained.
                      There are cheaper ways of insuring they will make it to
                      their court hearings, i.e. ankle bracelets, than
                      incarcerating them.
                    </aside>
                  </Body>
                </StyledAccordionItemBody>
              </StyledAccordionItem>
              <StyledAccordionItem>
                <StyledAccordionItemTitle
                  px={['1.5rem', '3rem', '3rem']}
                  pt="2rem"
                  pb="1rem"
                  className="u-position-relative"
                >
                  <h1
                    style={{
                      maxWidth: '36em',
                    }}
                  >
                    Call the Attorney General’s Office
                  </h1>
                  <div className="accordion__arrow" role="presentation" />
                </StyledAccordionItemTitle>
                <StyledAccordionItemBody>
                  <Body
                    style={{
                      maxWidth: '36em',
                      fontSize: '1.25rem',
                      lineHeight: '1.4',
                    }}
                    mx={['1.5rem', '3rem', '3rem']}
                  >
                    <h2>Do Not Dismantle Asylum Protections</h2>
                    <p>
                      Call the Attorney General’s Office and tell him you do not
                      agree with his decision to dismantle asylum protections
                      for victims of domestic violence and other vulnerable
                      refugees who suffer harm at the hands of private actors.
                    </p>
                    <p>
                      Comment Line: 202-353-1555<br />
                      Main Switchboard: 202-514-2000
                    </p>
                  </Body>
                </StyledAccordionItemBody>
              </StyledAccordionItem>
            </Accordion>
          </div>
        </Chunk>
        <Chunk>
          <h3>Get Involved with NASP</h3>
          <p>
            Are you interested in contributing your talents to NASP? Get in
            touch! Email us at{' '}
            <StyledA href="mailto:info@newamericanstoryproject.org">
              info@newamericanstoryproject.org
            </StyledA>.
          </p>
        </Chunk>
        <Chunk border>
          <h3>Spread the Word</h3>
          <div>
            <p>
              <StyledA
                href="https://www.micahbazant.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </StyledA>{' '}
              and print this poster by illustrator{' '}
              <StyledA
                href="https://www.micahbazant.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Micah Bazant
              </StyledA>{' '}
              to show support for immigrants in your community.
            </p>
            <Img fluid={poster.childImageSharp.fluid} />
          </div>
        </Chunk>

        <Resources>
          <h3>where to get legal help</h3>
          <ResourcesContent>
            <div>
              <p>
                <StyledA
                  href="https://www.immigrationadvocates.org/nonprofit/legaldirectory/"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="white"
                >
                  Click here
                </StyledA>{' '}
                for a national directory of nonprofit organizations that provide
                legal services to immigrants.
              </p>
            </div>
            <div>
              <p>
                <StyledA
                  href="https://www.immigrationadvocates.org/nonprofit/legaldirectory/search?state=CA"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="white"
                >
                  Click here
                </StyledA>{' '}
                for a directory of nonprofit organizations in California.
              </p>
            </div>
          </ResourcesContent>
        </Resources>

        <Chunk>
          <h3>Organizations helping immigrant children</h3>
          <div>
            <p>
              <StyledA
                href="https://supportkind.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kids in Need of Defense (KIND)
              </StyledA>{' '}
              is a national organization that represents unaccompanied immigrant
              children in removal proceedings.
            </p>
            <h4>KIND has offices in:</h4>
            <ul>
              <li>Atlanta, GA</li>
              <li>Baltimore, MD</li>
              <li>Boston, MA</li>
              <li>Houston, TX</li>
              <li>Los Angeles, CA</li>
              <li>New York, NY</li>
              <li>San Francisco, CA</li>
              <li>Fresno, CA</li>
              <li>Seattle, WA</li>
              <li>Washington D.C.</li>
            </ul>
            <p>
              <StyledA
                href="https://www.lsc-sf.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal Services for Children
              </StyledA>{' '}
              is a Bay Area nonprofit organization that provides free
              representation, mental health, and case management services to
              immigrant children and youth who are detained and or in removal
              proceedings.
            </p>
          </div>
        </Chunk>
        <Chunk>
          <h3>Organizations Helping Asylum Seekers</h3>
          <div>
            <p>
              <StyledA
                href="https://centrolegal.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Centro Legal de la Raza
              </StyledA>{' '}
              provides immigration legal services in the Bay Area and the
              Central Valley.
            </p>
            <p>
              <StyledA
                href="https://carecensf.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Central American Resource Center (CARECEN)
              </StyledA>{' '}
              has offices in San Francisco, New York, Los Angeles, and
              Washington D.C. and provides immigration legal services.
            </p>
            <h4>Regional CARECEN Websites:</h4>
            <ul className="single">
              <li>
                <StyledA
                  medium
                  href="https://www.carecen-la.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Los Angeles, CA{' '}
                </StyledA>
              </li>
              <li>
                <StyledA
                  medium
                  href="https://www.carecenny.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New York, NY
                </StyledA>
              </li>
              <li>
                <StyledA
                  medium
                  href="https://carecensf.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  San Francisco, CA
                </StyledA>
              </li>
              <li>
                <StyledA
                  medium
                  href="https://carecendc.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Washington D.C.
                </StyledA>
              </li>
            </ul>
            <p>
              <StyledA
                href="https://www.raicestexas.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Refugee and Immigrant Center for Education and Legal Services
                (RAICES)
              </StyledA>{' '}
              RAICES is a nonprofit organization that provides free and low cost
              legal services to underserved immigrant children, families, and
              refugees in Central and South Texas.
            </p>
          </div>
        </Chunk>
      </Wrapper>
    </Section>
  </div>
)

export default Content

import React from 'react'

import { Section, Text } from './styles'

const Head = () => (
  <div>
    <Section bg="black" color="white" pt="12rem" pb="10rem">
      <Text large>What can I do to help?</Text>
    </Section>
  </div>
)

export default Head
